@use "../config/" as *;
.webview{
    .modal-window .btn-close{
        display: none;
    }
}
.modal {
    &-window {
        --_logo-size: 4rem;
        --modal-head-height: 3rem;
        --modal-body-height: calc(var(--window-inner-height) - var(--modal-head-height));
        @extend %d-none;
    }

    &-head {
        .btn-close {
            top: 1.5rem;
            right: 1.5rem;
        }
        .btn-back {
            width: max-content;
            top: 1.5rem;
            left: 1.5rem;
        }
    }

    &-body {
        @extend %p-3;

        &-wrap {
            max-width: 120rem;
            padding: 0;
            overflow: hidden;
            @extend %flex-column;
            @extend %half-radius;
        }
    }
}

// fav club section 
.fav-club {
    &-head {
        @extend %secondary-bg;
        @extend %text-center;
        @extend %p-3;
        @extend %white;

        .title {
            @extend %uppercase;
            @extend %mb-0;
            @extend %font-18;
        }

        .text {
            opacity: 0.7;
            @extend %font-12;
        }
    }

    &-all {
        @extend %ml-auto;
        @extend %gap-1;
        @extend %flex-n-c;

        label {
            width: max-content;
            font-weight: 500;
            @extend %gap-2;
            @extend %flex-c-c;
        }

        .checkbox {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: .2rem;
            @include border(1, secondary);
        }
    }

    &-input {
        @extend %d-none;

        &:checked~.checkbox {
            @extend %primary-bg;
            @extend %white;
            @extend %flex-c-c;
            @include border(1, primary);

            &::after {
                @include icon(checkmark, 10);
                @extend %flex-c-c;
            }
        }
    }

    &-name {
        .name {
            @extend %font-12;
        }
    }

    &-section {
        max-width: 100rem;
        @extend %w-100;
        @extend %mx-auto;
        @extend %y-auto;
        @extend %flex-column;

        .head-wrap {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            z-index: var(--z-sync-cal);
            @extend %white-bg;
            @extend %flex-n-c;
            @extend %gap-1;
            @extend %px-3;
            @extend %py-2;
        }

        .title {
            @extend %mb-0;
            @extend %uppercase;
        }
    }

    &-list {
        flex: 1;
        @extend %y-auto;
        @extend %pb-3;
        @extend %pt-2;
        @extend %px-3;
        @include grid(3, var(--space-2));
    }

    &-item {
        isolation: isolate;
        @include border(1, white, 4);
        @extend %half-radius;
        @extend %p-2;
        @extend %medium-grey-bg;
        @extend %text-center;
        @extend %relative;
        @extend %quarter-radius;

        input[type="checkbox"] {
            @extend %d-none;

            &:checked {
                &~.radiomark {
                    @extend %primary-bg;

                    &::after {
                        background: transparent;
                        font-size: 1.4rem;
                        line-height: 0.5;
                        @include icon(checkmark);
                        @extend %flex-c-c;
                        @extend %w-100;
                        @extend %h-100;
                        @extend %white;
                    }
                }

                &~.club-logo {
                    &::after {
                        @extend %half-radius;
                        opacity: 1;
                    }
                }

                &~.fav-club-name {
                    @extend %white;

                    .name {
                        font-weight: 700;
                    }
                }
            }
        }

        .name {
            line-height: 1.2;
            @extend %font-10;
            @extend %d-block;
        }

        .club {
            &-img {
                width: var(--_logo-size);
                height: var(--_logo-size);
                object-fit: contain;
                @extend %m-auto;
            }

            &-logo {
                @extend %white-bg;
                @extend %circle-radius;
                @extend %p-1;

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    opacity: 0;
                    background: linear-gradient(var(--club-secondary), var(--club-primary));
                    z-index: var(--z-negative);
                    @extend %transition;
                }
            }
        }

        .radiomark {
            width: 2.3rem;
            height: 2.3rem;
            transform: translate(40%, -40%);
            border: 0;
            @extend %position-t-r;
        }

        .radio-container {
            flex-direction: column;
            justify-content: center;
            @extend %w-100;
            @extend %h-100;

            &::after {
                content: "";
                z-index: -1;
                @extend %quarter-radius;
                @extend %position-t-r;
                @extend %w-100;
                @extend %h-100;
            }
        }
    }

    &-stats {
        @extend %my-6;

        .title {
            @extend %mb-4;
            @extend %uppercase;
            @extend %font-14;
        }
    }

    &-footer {
        @extend %flex-c-n;
        @extend %py-2;

        .btn-outline {
            @include border(1, secondary-dark);
            @extend %secondary-dark;

            &:hover {
                @include border(1, primary);
                @extend %primary-bg;
                @extend %white;
            }
        }
    }
}

// apple-google
.social {
    &-wrapper {
        height: 40rem;
        @extend %flex-c-c;
    }

    &-list {
        gap: 2rem;
        @extend %flex-c-c;
    }

    &-link {
        @extend %flex-column-c-c;
    }

    &-name {
        .name {
            font-weight: 700;
            @extend %secondary-light;
            @extend %font-16;
        }
    }
}

.email {
    &-form {
        height: 40rem;
        @extend %flex-c-c;
    }

    &-element {
        @extend %flex-column-c-fs;
    }

    &-label {
        font-weight: 400;
        @extend %font-12;

        &::after {
            content: '*';
        }
    }

    &-input {
        @extend %mt-2;
        @extend %p-1;
        @include border(1, black, 1);
    }

    &-btn {
        font-weight: 700;
        border-radius: 0.2rem;
        @extend %secondary-dark;
        @extend %transparent-bg;
        @extend %w-100;
        @extend %py-2;
        @extend %mt-6;
        @extend %font-14;
        @extend %capitalize;
        @include border(1, secondary-dark);

        &:hover {
            @extend %white;
            @extend %primary-bg;
            @include border(1, primary);
        }
    }
}

@include mq(col-md) {
    .modal-window {
        --_logo-size: 6rem;
    }

    .fav-club {
        &-head {
            padding-block: var(--space-5);

            .title {
                font-size: 2.6rem;
            }

            .text {
                font-size: 1.4rem;
            }
        }

        &-all {
            label {
                font-size: 1.6rem;
            }
        }

        &-list {
            grid-template-columns: repeat(6, 1fr);
        }

        &-item {
            width: 100%;
            height: 100%;
            display: grid;
            align-content: center;

            .name {
                font-size: 1.4rem;
                font-weight: 800;
            }

            .club-img {
                width: 9rem;
                height: 9rem;
                padding: var(--space-2);
            }
        }

        &-name {
            margin-top: var(--space-1);
        }

        &-stats {
            .stats {
                &-list {
                    grid-template-columns: 18rem 1fr 1fr 1fr;
                    gap: 2rem;
                }

                &-item {
                    padding: 1rem;
                    border-radius: var(--quarter-radius);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: var(--space-2);

                    &-fav {
                        width: 18rem;
                        padding: 0;
                    }

                    &-wdl {
                        .stats-data {
                            flex: 1;
                        }
                    }
                }

                &-text {
                    font-size: 1rem;
                }

                &-data {
                    flex: unset;
                }

                &-number {
                    font-size: 3.5rem;
                    padding: 0;
                }

                &-img {
                    width: 5rem;
                    height: 5rem;
                }

                &-image {
                    position: unset;
                }
            }
        }

        &-footer {
            padding-block: 2rem;
        }
    }
}