@use "../config" as *;
@forward "common-fixtures";
.hide-sync-calander {
  &.waf-fixtures .waf-head .head-wrap > a {
    display: none;
  }
}
.waf-fixtures {
  @extend %light-grey-bg;
  .waf-head {
    .head {
      &-wrap {
        @extend %flex-wrap;
      }
      &-disclosure {
        @extend %w-100;
        @extend %white;
        font-size: 1rem;
        a {
          text-decoration: underline;
          @extend %primary;
        }
      }
    }
  }
  &.waf-component {
    @extend %p-1;
    @extend %mx-2-neg;
  }
  .filter-section {
    .selected-title {
      @extend %secondary-dark;
    }
    .select-box-wrap {
      @extend %pl-2;
      @extend %pt-1;
    }
    .select-list {
      position: unset;
      clip-path: unset;
      pointer-events: initial;
      .list-item {
        @extend %secondary-dark;
        &::before {
          border-color: var(--secondary-dark);
        }
      }
    }
  }
  .head-wrap {
    .head-tab {
      bottom: -5rem;
      right: var(--space-3);
      width: calc(100% - 3rem);
      max-width: 40rem;
      @extend %p-0;
      @extend %gap-2;
      @extend %flex-fe-c;
      @extend %position-b-r;
      .download-pdf,
      .sync-calender,
      li {
        min-width: max-content;
        width: 100%;
        transition: color 300ms, background-color 300ms;
        @include border(1, secondary-light);
        @extend %white-bg;
        @extend %quarter-radius;
        @extend %secondary-light;
        &:hover {
          border-color: var(--primary);
          @extend %font-12-pm;
          @extend %primary-bg;
          @extend %white;
          a {
            font-weight: 500;
          }
        }
        a {
          color: inherit;
          @extend %flex-c-c;
          @extend %gap-1;
          @extend %font-12-pm;
          &:hover {
            color: inherit;
            @extend %font-12-pm;
          }
        }
      }
      .download-pdf {
        max-width: 14rem;
        a {
          &::before {
            @include icon(pdf, 16)
          }
        }
      }
    }
    & > a {
      min-width: max-content;
      max-width: 20rem;
      transition: color 300ms, background-color 300ms;
      @extend %capitalize;
      @extend %p-1-3;
      @extend %white-bg;
      @extend %secondary-light;
      @extend %flex-c-c;
      @extend %gap-1;
      @extend %font-12-pm;
      @include border(1, secondary-light);
      @include position(null, null, var(--space-10-neg), var(--space-2));
      &:hover {
        border-color: var(--primary);
        @extend %font-12-pm;
        @extend %primary-bg;
        @extend %white;
      }
      &::before {
        @include icon(add-calander, 16)
      }
    }
  }
  .layout-wrapper {
    @extend %relative;
  }
  .waf {
    &-head {
      @extend %relative;
      @extend %mb-7;
      .title {
        @extend %font-18;
      }
    }
    &-body {
      padding-top: var(--space-10);
      @extend %px-2;
    }
  }
  .card {
    &-list-item:first-child {
      @extend %mt-6;
    }
    &-list-item:not(:last-child) {
      @extend %mb-6;
    }
    &-item-wrapper {
      padding: var(--space-2);
      flex-direction: column;
      gap: 0;
      @extend %white-bg;
      &:not(:last-child) {
        @extend %mb-3;
      }
      &.recent {
        transition: background-color .3s ease-in;
        &:hover {
          border-radius: .2rem;
          @extend %secondary-light-bg;
          .btn-highlight,
          .btn-report,
          .btn-preview {
            border-color: var(--white);
            @extend %secondary-light-bg;
            &::before,
            .text {
              @extend %white;
            }
          }
          .btn-highlight::before {
            border-color: var(--white);
          }
          .btn-matchcenter::before {
            right: var(--space-1);
            color: var(--white)
          }
          .team-anchor {
            @extend %white-5;
          }
          .venue,
          .won .full {
            @extend %white;
          }
          .full,
          .short {
            @extend %white-5;
          }
          .team-time-status .text {
            @extend %accent;
          }
          .card {
            &-action {
              @include border(1, white, 2, top);
            }
            &-client {
              border: .2rem solid hsl(var(--hsl-white)/0.5);
              background-image: url('/static-assets/images/data-partners/jio-hotstar-reverse.png?v=#{$image-version}');
              background-size: 6.6rem;
              background-repeat: no-repeat;
              background-position: center;
              width: 9.6rem;
              height: 2.6rem;
              .image {
                visibility: hidden;
              }
            }
          }
          .card-body {
            &[data-gameweek] {
              &::before {
                border-radius: .2rem;
                @extend %secondary-bg;
                @extend %medium-grey;
              }
            }
          }
        }
        .btn-ticket {
          @extend %d-none;
        }
      }
      &.live {
        isolation: isolate;
        &::after {
          content: "";
          background-size: 300%;
          background-position: center;
          z-index: var(--z-negative);
          @extend %position-center;
          @include bgImg('cssimages/live-pattern.png');
        }
        &:hover {
          .btn-matchcenter::before {
            right: var(--space-1);
          }
        }
        .btn-ticket,
        .btn-preview,
        .btn-highlight {
          background: transparent;
          color: var(--white);
          border-color: hsl(var(--hsl-white)/0.6);
          &::before {
            border-color: var(--white);
            color: var(--white);
          }
          .text {
            color: var(--white);
          }
          // @extend %d-none;
        }
        .card-action {
          border-top-color: hsl(var(--hsl-white) / .1);
        }
        .card-client {
          border-color: hsl(var(--hsl-white) / .5);
          @extend %relative;
          &::after {
            content: "live on";
            padding: .3rem .7rem;
            translate: 0 -50%;
            font-weight: 700;
            @include position(0, var(--space-1));
            @extend %rounded-radius;
            @extend %success-bg;
            @extend %white;
            @extend %font-8;
            @extend %uppercase;
          }
        }
        .card-body {
          &[data-gameweek] {
            &::before {
              border-radius: 0 0 1rem 1rem;
              @extend %secondary-light-bg;
              @extend %medium-grey;
            }
          }
        }
      }
      &.upcoming {
        .btn-highlight,
        .team-score {
          @extend %d-none;
        }
      }
    }
    &-head {
      order: 1;
      @extend %py-3;
    }
    &-meta {
      @extend %text-center;
      .venue {
        font-size: 1rem;
        &::before {
          @include icon(stadium);
          @extend %mr-1;
        }
      }
    }
    &-body {
      position: unset;
      @extend %pb-2;
      @extend %mt-10;
      .team-name {
        z-index: var(--z-default);
        cursor: pointer;
      }
      &[data-gameweek] {
        &::before {
          content: attr(data-gameweek);
          border-radius: .2rem;
          margin: 0 auto;
          width: max-content;
          @extend %club-primary-bg-1;
          @include position(0, 0, null, 0);
          @extend %py-2;
          @extend %px-6;
          @extend %font-12-pb;
          @extend %secondary-dark;
        }
      }
    }
    &-meta-title {
      @extend %mb-3;
      @extend %font-16;
    }
    &-client {
      border: .1rem solid var(--secondary-dark);
      background-color: transparent;
      width: 9.6rem;
      height: 2.6rem;
      justify-content: center;
      .image {
        width: 5.6rem;
        height: 1.6rem;
      }
    }
    &-action {
      order: 2;
      border-top: .1rem solid hsl(var(--hsl-secondary-light) / .2);
      flex-direction: row-reverse;
      gap: var(--space-2);
      @extend %pt-2;
      @extend %flex-c-c;
      .btn-site {
        height: 2.6rem;
        @extend %px-3;
      }
      .btn-matchcenter {
        height: 100%;
      }
    }
  }
  .team {
    &-name {
      @extend %font-0;
    }
  }
  .btn-site,
  .card-client {
    border: .1rem solid var(--secondary-light);
    font-weight: 500;
    gap: var(--space-1);
    @extend %p-1-2;
    @extend %quarter-radius;
    @extend %flex-n-c;
    @extend %white-bg;
    &:not(.btn-matchcenter) {
      @extend %relative;
      z-index: var(--z-fixtures-page-btn);
    }
    &::before {
      font-weight: normal;
      @extend %secondary-light;
    }
    .text {
      width: max-content;
      @extend %secondary-light;
      @extend %font-10;
    }
  }
  .title {
    @extend %mb-0;
  }
  .card-client {
    @extend %p-0;
    .link {
      @extend %p-1-2;
    }
  }
  .btn-matchcenter {
    border: 0;
  }
  .btn-ticket,
  .btn-highlight {
    &.btn-site {
      border-color: var(--primary);
      &::before {
        @extend %primary;
      }
      .text {
        @extend %primary;
      }
    }
  }
  .btn-preview {
    &::before {
      @include icon(preview, 10)
    }
  }
  .btn-report {
    &::before {
      @include icon(file, 10);
    }
  }
  .btn-highlight {
    &::before {
      width: 1.2rem;
      height: 1.2rem;
      border: .1rem solid var(--primary);
      @include icon(play, 6);
      @extend %circle-radius;
      @extend %flex-c-c;
    }
  }
  .btn-ticket {
    &::before {
      @include icon(ticket, 10);
    }
  }
  .tab-section,
  .date,
  .number,
  .time,
  .match,
  .full,
  .card-number {
    @extend %d-none;
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-fixtures {
    .waf-head {
      .head-wrap > a {
        left: unset;
        right: 17rem;
      }
    }
    .layout-wrapper {
      padding-inline: 0;
    }
    .waf-body {
      padding-top: 0;
    }
    .card {
      &-item-wrapper {
        padding: 1.2rem var(--space-12) 1.2rem var(--space-4);
        align-items: center;
        flex-direction: row;
        gap: var(--space-4);
        &.live {
          &::after {
            background-size: cover;
          }
          .full {
            color: var(--white);
          }
          .btn-matchcenter::before {
            color: var(--secondary-light);
          }
          .card-body {
            &[data-gameweek] {
              &::before {
                border-radius: .2rem;
              }
            }
          }
        }
        &.recent {
          &:hover {
            .btn-matchcenter:before {
              color: var(--secondary-dark);
            }
          }
        }
      }
      &-head {
        flex-basis: 15%;
        order: 4;
      }
      &-body {
        width: unset;
        padding-bottom: 0;
        margin-left: unset;
        order: 1;
        margin-top: unset;
        padding-left: var(--space-14);
        position: unset;
        &[data-gameweek] {
          &::before {
            top: 0;
            left: 0;
            right: unset;
            padding: 0;
            height: 100%;
            width: 7.7rem;
            text-align: center;
            @include flex-config(flex, null, center, center);
          }
        }
      }
      &-action {
        padding-top: 0;
        border-top: 0;
        flex-basis: auto;
        flex-direction: row;
        order: 3;
        .btn-matchcenter {
          height: unset;
        }
      }
      &-client {
        order: 3;
        .image {
          width: 8.4rem;
          height: 2.4rem;
        }
      }
      &-meta {
        .venue {
          text-align: left;
        }
      }
    }
    .waf-body {
      padding-inline: 0;
    }
    .team {
      flex-basis: unset;
      gap: var(--space-4);
      justify-content: space-between;
      &-name {
        max-width: 11rem;
      }
      &-a {
        .team-name {
          text-align: right;
          line-height: 1.1;
        }
      }
      &-info {
        width: 15rem;
      }
      &-score {
        width: 6rem;
      }
      &-status {
        margin-inline: var(--space-7);
      }
    }
    .short {
      display: none;
    }
    .full {
      display: inline-block;
      color: hsl(var(--hsl-secondary-light) / .7);
      font-size: 1.4rem;
      font-weight: 700;
    }
    .won .full {
      color: hsl(var(--hsl-secondary-light));
    }
    .venue {
      font-size: 1rem;
      gap: var(--space-1);
      line-height: 1.2;
      @include flex-config(flex, null, null, center);
      &::before {
        @include icon(stadium, 10);
      }
    }
    .btn-matchcenter::before {
      width: 3rem;
      height: 3rem;
      border-radius: 100vmax;
      top: 50%;
      right: var(--space-3);
      background-color: var(--light-grey);
      translate: 0 -50%;
      transition: right .3s ease-in;
      @include flex-config(flex, null, center, center);
    }
  }
}